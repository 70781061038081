<template>
  <div>
    <div class="lightestGreyBackground external-calc-height">
      <div :class="{ 'full-width': fullWidthWrapperEnabled, 'functions-section' : true }">
        <VasionButton
          id="btnFullWidth"
          name="btnFullWidth"
          class="full-width-btn"
          :title="`Turn Full Width ${fullWidthWrapperEnabled ? 'Off' : 'On'}`"
          :icon="fullWidthWrapperEnabled ? 'VasionExitFullscreenIcon' : 'VasionFullscreenIcon'"
          @vasionButtonClicked="toggleFullwidhtContainer"
        />
      </div>

      <div class="form-container">
        <div
          :class="{ 'full-width': fullWidthWrapperEnabled, 'form-wrapper' : true }"
        >
          <StartWorkflowManager
            v-if="showFormUI"
            :viewMode="true"
            @workflowStarted="showSnack(true)"
            @workflowFailed="showSnack(false)"
          />
          <div v-if="!isLoading && !showFormUI" id="forbidden-container">
            <VasionRemoveCircleIcon id="no-entry-icon" />
            <div id="message">
              You are not authorized to access this form.
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer" v-if="isAmplitudeAvailable">
        <p>We use analytics to improve the application performance, for more information see Vasion's <a href="https://vasion.com/cookie-information/" target="_blank">Cookie</a> and <a href="https://vasion.com/privacy-policy/" target="_blank">Privacy Policy</a>.</p>
      </div>
      <VasionSnackbar
        id="results-snack"
        :showSnackbarBool.sync="showSnackbar"
        :snackbarImage="snackbarImage"
        :snackbarTitle="snackbarTitle"
      />
    </div>
  </div>
</template>

<script>
import StartWorkflowManager from '@/components/workflow/StartWorkflowManager.vue'

export default {
  name: 'PlainForm',
  components: {
    StartWorkflowManager,
  },
  data: function () {
    return {
      fullWidthWrapperEnabled: false,
      isAmplitudeAvailable: false,
      isAuthed: false,
      isLoading: true,
      publicAccessible: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarTitle: '',
    }
  },
  computed: {
    eForm() { return this.$store.state.forms.eForm },
    showFormUI() { return this.isAuthed || this.publicAccessible },
  },
  watch: {
    eForm: {
      handler() {
        if (this.$store.state.forms.eForm && this.$store.state.forms.eForm.PublicAccessible) {
          this.publicAccessible = true
        } else if (this.$store.state.forms.eForm && !this.$store.state.forms.eForm.PublicAccessible) {
          this.$router.push({ name: 'TheLogin', query: { redirect: `/form/${this.$route.params.formName}/${this.$route.params.formID}` } })
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.$route.params.formID === 0) {
      this.publicAccessible = false
      this.isAuthed = false
      return
    }
    await this.$store.dispatch('forms/getEForm', this.$route.params.formID)
    if (!this.$store.state.forms.eForm?.PublicAccessible && !this.$store.state.common.isSignedIn) {
      this.$router.push({ name: 'TheLogin', query: { redirect: `/formLink/${this.$route.params.formName}/${this.$route.params.formID}` } })
    } else if (this.$store.state.forms.eForm?.PublicAccessible) {
      this.publicAccessible = true
    } else {
      this.isAuthed = await this.$store.dispatch('forms/checkIfUserCanAccessEForm', this.$route.params.formID)
    }

    await this.$amplitude.checkAmplitudeAvailability(true)
    this.isAmplitudeAvailable = await this.$amplitude.isAvailable()
  
    this.isLoading = false
  },
  methods: {
    showSnack(val) {
      this.snackbarTitle = val ? `${$layoutsLabel} Submitted!` : `${$layoutsLabel} Submission Failed!`
      this.snackbarImage = val
      this.showSnackbar = true
    },
    toggleFullwidhtContainer() {
      this.fullWidthWrapperEnabled = !this.fullWidthWrapperEnabled
      this.$root.$emit('update-zoom', this.fullWidthWrapperEnabled)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.external-calc-height {
  height: 100vh;
  width: 100vw;
}
#forbidden-container {
  height: calc(100vh - 160px);
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  #message {
    font-family: $font-family-bold;
    margin-top: 20px;
    position: relative;
    font-size: 30px;
    bottom: 10%;
  }
  #no-entry-icon {
    fill: $grey-600;
    position: relative;
    bottom: 10%;
  }
}
.lightestGreyBackground {
  background-color: $grey-75;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.functions-section {
  margin: 8px 0 0 0;
  width: 100%;
  text-align: end;
}
.full-width-btn {
  display: none !important;
}
.form-container {
  width: 100%;
  height: calc(100vh - 74px);
  padding: 8px;
  display: flex;
  justify-content: center;
}
.form-wrapper {
  border-radius: 0.5rem;
  box-shadow: 0 .125rem .25rem rgba(33,37,41,.075);
  background-color: $white;
  overflow-y: auto;
  max-height: 100vh;
  width: 100%;
}
.full-width {
  width: 100% !important;
}
.full-width.functions-section {
  width: 100% !important;
  padding-right: 1rem;
}
.form-footer {
  width: 100%;
  padding-inline: 10px;
}
.form-footer p {
  margin: unset;
}

@media (min-width: $breakpoint-md) {
  .full-width-btn {
    display: block !important;
  }
  .form-container {
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    max-height: calc(100vh - 114px);
    width: 736px;
  }
  .functions-section, .form-footer {
    width: 736px;
  }
  .form-footer {
    padding-inline: unset;
  }
}
@media (min-width: 950px) and (max-width: 991.98px) {
  .form-wrapper {
    max-height: calc(100vh - 44px);
    width: 918px - 256px;
  }
  .functions-section, .form-footer {
    width: 918px - 256px;
  }
}
@media (min-width: $breakpoint-lg) {
  .form-container {
    height: calc(100vh - 74px);
  }
  .form-wrapper {
    max-height: calc(100vh - 44px);
    width: 960px - 256px;
  }
  .functions-section, .form-footer {
    width: 960px - 256px;
  }
}
@media (min-width: $breakpoint-xl) {
  .form-wrapper {
    max-height: calc(100vh - 44px);
    width: 1168px - 256px;
  }
  .functions-section, .form-footer {
    width: 1168px - 256px;
  }
}
@media (min-width: $breakpoint-xxl) {
  .form-wrapper {
    max-height: calc(100vh - 44px);
    width: 1368px - 256px;
  }
  .functions-section, .form-footer {
    width: 1368px - 256px;
  }
}
</style>
